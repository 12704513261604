import { useRef } from "react";
import { useQuery, useMutation, useQueryClient, useMutationState } from "@tanstack/react-query";
import axios from "axios";
import isEqual from "react-fast-compare";
import { QuoteAPI } from "../utils/QuoteAPI";
import { QuoteDataUtils } from "../utils/QuoteDataUtils";
import { Quote } from "../types/Quote.interface";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { useThrottleOneAtATime } from "@/shared/hooks/useThrottleOneAtATime";

/**
 * `useQuote` Custom Hook
 *
 * This hook is designed to manage the fetching, updating, and default state of a Quote.
 *
 * @param quoteId - Optional. The ID of the quote to fetch.
 *
 * @returns
 *  - quoteQuery: The React Query object containing the current quote state, refetch, etc.
 *  - updateQuote: A mutation function to update the quote. Accepts a Quote object as parameter.
 *  - isQuoteUpdating: A boolean flag indicating if any quote mutation is currently pending.
 */

export type UseQuoteProps = {
    quoteId?: string;
    underwriter: PetUnderwriterType;
    setQuoteId?: (quoteId: string) => void;
};

export const useQuote = ({ quoteId, underwriter, setQuoteId }: UseQuoteProps) => {
    // Ref to store the last quote data
    const lastQuoteDataRef = useRef<Quote | null>(null);

    const quoteApi = new QuoteAPI(underwriter);
    const queryClient = useQueryClient();
    const updateQuoteAPI = useThrottleOneAtATime(async (quoteData: Quote, quoteApi: QuoteAPI): Promise<Quote> => {
        try {
            const petQuote = QuoteDataUtils.quoteToPetQuote(quoteData);
            const updatedPetQuote = await quoteApi.updateQuote(petQuote);
            const petQuoteToQuote = QuoteDataUtils.petQuoteToQuote(updatedPetQuote, underwriter);
            return petQuoteToQuote;
        } catch (error) {
            throw error;
        }
    });

    const quoteQuery = useQuery({
        queryKey: ["quote", quoteId],
        queryFn: async ({ queryKey }) => {
            const [, _quoteId] = queryKey;
            if (!_quoteId) return {};
            try {
                const petQuote = await quoteApi.getQuote(_quoteId);
                if (!!petQuote) {
                    const petQuoteToQuote = QuoteDataUtils.petQuoteToQuote(petQuote, underwriter);
                    return petQuoteToQuote;
                }
                return {};
            } catch (err) {
                if (axios.isAxiosError(err)) throw err;
            }
        }
    });

    const updateQuote = useMutation<Quote, Error, Quote>({
        retry: 2,
        retryDelay: 1000,
        mutationKey: [`quote`],
        mutationFn: async (quoteData: Quote) => {
            if (!!lastQuoteDataRef.current && isEqual(lastQuoteDataRef.current, quoteData)) {
                return lastQuoteDataRef.current;
            }
            try {
                const result = await updateQuoteAPI(quoteData, quoteApi);
                if (!result) {
                    throw new Error("No result returned from updateQuoteAPI");
                }
                lastQuoteDataRef.current = result;
                return result;
            } catch (err) {
                if (axios.isAxiosError(err)) throw err;
                throw new Error("Error updating quote");
            }
        },
        onSuccess: (data, variables) => {
            if (!quoteId && !!data.id && !!setQuoteId) {
                setQuoteId(data.id);
            }
            queryClient.setQueryData([`quote`, variables?.id], data);
        }
    });

    const mutationState = useMutationState({ filters: { status: `pending`, mutationKey: [`quote`] } });

    return { queryClient, quoteQuery, updateQuote, isQuoteUpdating: mutationState.length > 0 };
};
